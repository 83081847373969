import React from 'react'

const VisionIcon1Inline = (props) => {
  return React.createElement("svg", props, React.createElement("g", {
    "stroke": "#00EBAA",
    "strokeWidth": "2",
    "fill": "none",
    "fillRule": "evenodd",
    "strokeLinecap": "round",
    "strokeLinejoin": "round"
  }, [React.createElement("path", {
    "d": "M1 15h4v1.5A2.5 2.5 0 007.5 19h0a2.5 2.5 0 002.5-2.5V15h10v-1.5a2.5 2.5 0 012.5-2.5h0a2.5 2.5 0 012.5 2.5V15h4",
    "key": 0
  }), React.createElement("path", {
    "d": "M15 29v-4h1.5a2.5 2.5 0 002.5-2.5h0a2.5 2.5 0 00-2.5-2.5H15V10h-1.5A2.5 2.5 0 0111 7.5h0A2.5 2.5 0 0113.5 5H15V1",
    "key": 1
  }), React.createElement("path", {"d": "M1 1h28v28H1z", "key": 2})]));
}

VisionIcon1Inline.defaultProps = {"width": "30", "height": "30"};

export default VisionIcon1Inline
