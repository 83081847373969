import React from 'react'

const HireIcon4Inline = (props) => {
  return React.createElement("svg", props, React.createElement("g", {
    "stroke": "#00EBAA",
    "strokeWidth": "2",
    "fill": "none",
    "fillRule": "evenodd",
    "strokeLinecap": "round",
    "strokeLinejoin": "round"
  }, [React.createElement("path", {
    "d": "M8.952 16.958l2.661 2.66 5.322-5.32M15.16 3.66a2.66 2.66 0 00-5.32 0H7.178v3.546H17.82V3.66h-2.66z",
    "key": 0
  }), React.createElement("path", {"d": "M20.482 4.546h2.66v23.051H1.858V4.547h2.66", "key": 1})]));
}

HireIcon4Inline.defaultProps = {"width": "25", "height": "29"};

export default HireIcon4Inline
