import React from 'react';

import './about.scss';

const About = () => {
    return (
        <section className="about" id="about">
            <div className="container">
                <div className="hero__hands">
                    <div className="circle hero__hands-circle-1" />
                    <div className="circle hero__hands-circle-2" />
                    <p className="g-subtitle" data-aos="">Easy in, Easy out</p>
                    <div className="hero__hands-row">
                        <p className="g-h3" data-aos="fade-up" data-aos-delay="300">Buying real estate in Africa should not be as hard as it is.</p>
                        <p className="g-p1" data-aos="fade-up" data-aos-delay="600">Afrivest is working to democratize access to the best houses in Africa.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;