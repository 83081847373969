import React from 'react';
import Plx from 'react-plx';

import Icon1 from '../../assets/images/vision-icon-1.inline';
import Icon2 from '../../assets/images/vision-icon-2.inline.js';
import Icon3 from '../../assets/images/vision-icon-3.inline.js';

import { parallaxCircles2 } from '../../utils'

import './our-vision.scss'

const parallaxRotate = [
    {
        start: '.vision',
        end: '.footer',
        properties: [
            {
                startValue: -30,
                endValue: 30,
                unit: 'deg',
                property: 'rotate',
            },
            {
                startValue: 0,
                endValue: 500,
                property: 'translateY',
            },
        ],
    },
];

const OurVision = () => {
    return (
        <section className="vision" id="services">
            <div className="container">
                <Plx parallaxData={ parallaxRotate } className="vision__circles" />
                <Plx parallaxData={ parallaxCircles2 } className="vision__circle" />

                <div className="row">
                    <div className="col-xs-12 col-md-8 col-md-offset-2">
                        <p className="g-subtitle" data-aos="">Our vision</p>
                        <h2 className="g-h2" data-aos="fade-up" data-aos-delay="300">Quickly and easily buy property in any African country.</h2>
                        <p className="g-p1" data-aos="fade-up" data-aos-delay="600">  Starting with 🇧🇫, 🇳🇬, 🇿🇦, 🇨🇮, 🇰🇪. </p>
                    </div>
                </div>
                <div className="row vision__icons">
                    <div className="col-xs-12 col-sm-4">
                        <div className="vision__icons-icon" data-aos="">
                            <Icon1 />
                        </div>
                        <p className="g-p1 color-white" data-aos="" data-aos-delay="75"><span>Secure</span></p>
                        <p className="g-p2" data-aos="fade-up" data-aos-delay="150">No more worrying, no more stres. Afrivest takes the pain out of purchasing real estate throughout Africa.</p>
                    </div>``
                    <div className="col-xs-12 col-sm-4">
                        <div className="vision__icons-icon" data-aos="" data-aos-delay="300">
                            <Icon2 />
                        </div>
                        <p className="g-p1 color-white" data-aos="" data-aos-delay="375"><span>Reliable</span></p>
                        <p className="g-p2" data-aos="fade-up" data-aos-delay="450">Every seller undergoes a thorough vetting process before being allowed on the platform.</p>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <div className="vision__icons-icon" data-aos="" data-aos-delay="600">
                            <Icon3 />
                        </div>
                        <p className="g-p1 color-white" data-aos="" data-aos-delay="675"><span>Affordable</span></p>
                        <p className="g-p2" data-aos="fade-up" data-aos-delay="750">Our pricing is fair and we promise to never charge exhorbitant fees.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurVision;
