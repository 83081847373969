import React from 'react'

const VisionIcon3Inline = (props) => {
  return React.createElement("svg", props, React.createElement("g", {
    "stroke": "#00EBAA",
    "strokeWidth": "2",
    "fill": "none",
    "fillRule": "evenodd",
    "strokeLinecap": "round",
    "strokeLinejoin": "round"
  }, [React.createElement("path", {
    "d": "M1 1h30v26H1zM1 6h30M7 1v5",
    "key": 0
  }), React.createElement("path", {"d": "M12 13l-3 3 3 3M20 13l3 3-3 3", "key": 1})]));
}

VisionIcon3Inline.defaultProps = {"width": "32", "height": "28"};

export default VisionIcon3Inline
