import React from 'react'

const HireIcon2Inline = (props) => {
  return React.createElement("svg", props, React.createElement("path", {
    "d": "M19.708 23.994l5.322-5.306m-5.765-5.749l5.765 5.749.887 6.19-6.209-.884-5.765-5.748 5.322-5.307zM18.82 1l6.208 6.19L7.29 24.878l-6.208-6.19 6.652-6.633L1.97 6.306 7.292 1l5.765 5.749L18.82 1zM9.952 9.844l2.661 2.653m-5.321 0l1.773 1.769m6.209-9.728l2.66 2.653m-5.32 0l1.773 1.769M4.63 15.15l2.66 2.654",
    "stroke": "#00EBAA",
    "strokeWidth": "2",
    "fill": "none",
    "fillRule": "evenodd",
    "strokeLinecap": "round",
    "strokeLinejoin": "round"
  }));
}

HireIcon2Inline.defaultProps = {"width": "28", "height": "26"};

export default HireIcon2Inline
